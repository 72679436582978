.footer {
    display        : flex;
    flex-direction : column;
    justify-content: center;
}

.footer-qc {
    display        : flex;
    justify-content: center;
}

.footer-wx {
    display        : flex;
    justify-content: center;
}

.copyright {
    padding-left   : 20px;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    min-height: 150px;
}