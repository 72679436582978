.header-nav {
    display         : flex;
    background-color: white;
    align-items     : center;
    padding         : 0;

    margin: 0;

    .logo {
        background-color: white;
        width           : 210px;
        display         : flex;
        justify-content : center;
        align-items     : center;
    }
}